import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Button, Drawer, Input, PhoneInput, Select, Spinner } from "@tecma/ds";
import {
  PhoneCountry,
  getCountryAndPrefix,
} from "@tecma/ds/lib/components/PhoneInput/utils/functions";
import { Locales } from "@tecma/ds/lib/components/PhoneInput/utils/locales";
import type { OptionSelect } from "@tecma/ds/lib/components/Select/Select";

import { ROLES } from "constants/role";
import { NAME_REGEX, EMAIL_REGEX } from "constants/regex";
import { useStore } from "store/storeUtils";
import useFieldValidator from "customHooks/useFieldValidator";
import useCreateBackOfficeUser from "customHooks/useCreateBackOfficeUser";

import "./account-manager-drawer.scss";

export interface AccountFieldErrors {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  language?: string;
  role?: string;
  assets?: string;
}

export interface AccountFields {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  language?: OptionSelect;
  role?: OptionSelect;
  assets?: OptionSelect[];
}
interface AccountManagerDrawerProps extends AccountFields {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  accountFields?: AccountFields;
}

const roleOptions: OptionSelect[] = [
  {
    label: ROLES.ACCOUNT_MANAGER.label,
    value: ROLES.ACCOUNT_MANAGER.value,
  },
  {
    label: ROLES.VENDOR.label,
    value: ROLES.VENDOR.value,
  },
  {
    label: ROLES.VENDOR_MANAGER.label,
    value: ROLES.VENDOR_MANAGER.value,
  },
  {
    label: ROLES.PROPRIETA.label,
    value: ROLES.PROPRIETA.value,
  },
  {
    label: ROLES.FRONT_OFFICE.label,
    value: ROLES.FRONT_OFFICE.value,
  },
  {
    label: ROLES.BUILDING_MANAGER.label,
    value: ROLES.BUILDING_MANAGER.value,
  },
];

const emptyAccountFields: AccountFields = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  language: undefined,
  role: undefined,
  assets: [],
};

// TODO: use if want prefix
// const getCountryPrefix = (countryLabel: string) => {
//   return `+${countryLabel.split("+")[1]}`;
// };

const AccountManagerDrawer: React.FC<AccountManagerDrawerProps> = observer(
  ({ isOpen, setIsOpen, accountFields }) => {
    const { i18n, t } = useTranslation();
    const store = useStore();
    const [fields, setFields] = useState<AccountFields>(
      accountFields || emptyAccountFields,
    );
    const [fieldErrors, setFieldErrors] = useState<AccountFieldErrors>();
    const initialPhoneCountry = getCountryAndPrefix(
      (i18n.language.split("-")[1] || "IT") as PhoneCountry,
      i18n.language.split("-")[0] as Locales,
    );
    const [country, setCountry] = useState<OptionSelect>(initialPhoneCountry);
    const languageOptions: OptionSelect[] =
      store.languages?.map((lang) => ({
        label: t(`iTd.language.${lang}`),
        value: lang,
      })) || [];
    const {
      isFieldEmpty,
      isMaxLengthExceeded,
      isFormatInvalid,
      isValidPhoneNumber,
      hasFormErrors,
      getFieldMaxLength,
      getFieldError,
    } = useFieldValidator(fields, fieldErrors, setFieldErrors);
    const { createBackOfficeUser, backOfficeUserITD } = useCreateBackOfficeUser(
      fields,
      () => setIsOpen(false),
      setFieldErrors,
    );

    // Handlers
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      let error = false;
      if (name === "name" || name === "surname") {
        error = isMaxLengthExceeded(
          name,
          value.length,
          getFieldMaxLength(name),
        );
      } else if (name === "email") {
        error = isMaxLengthExceeded(
          name,
          value.length,
          getFieldMaxLength(name),
        );
      } else if (name === "phone") {
        error = !isValidPhoneNumber(country.value as PhoneCountry, value);
      }
      setFields((prevFields) => ({
        ...prevFields,
        [name]: name === "email" ? value.trim() : value,
      }));
      if (!error) {
        if (fieldErrors && fieldErrors[name as keyof AccountFields]) {
          setFieldErrors((err) => ({ ...err, [name]: null }));
        }
      }
    };
    const handleSelectChange = (field: string, value: OptionSelect) => {
      setFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
      if (value) {
        setFieldErrors((err) => ({ ...err, [field]: null }));
      }
    };
    const handleOnUpdateFilters = (filters: OptionSelect[] | any) => {
      setFields((prevFields) => ({
        ...prevFields,
        assets: filters,
      }));
      if (filters?.length) {
        setFieldErrors((err) => ({ ...err, assets: undefined }));
      }
    };
    const handleChangeCountry = (newValue: OptionSelect | OptionSelect[]) => {
      if (fields.phone) {
        const isValid = isValidPhoneNumber((newValue as OptionSelect).value);
        if (isValid && fieldErrors?.phone) {
          setFieldErrors((err) => ({ ...err, phone: undefined }));
        }
      }
      setCountry(newValue as OptionSelect);
    };
    const handleSubmit = () => {
      if (backOfficeUserITD.loading) return;
      let errors = {};
      Object.keys(fields).forEach((field) => {
        if (field !== "phone" && isFieldEmpty(field)) {
          errors = { ...errors, [field]: "isRequired" };
        }
      });
      if (Object.keys(errors).length > 0) {
        setFieldErrors((currErrors) => ({ ...currErrors, ...errors }));
      } else if (!hasFormErrors()) {
        createBackOfficeUser();
      }
    };

    useEffect(() => {
      if (isOpen && !accountFields?.assets) {
        setFields((currFields) => ({
          ...currFields,
          assets: store.projectsFilterOptions ?? [],
        }));
      }
      if (fieldErrors) {
        setFieldErrors(undefined);
      }
      if (!isOpen) {
        setCountry(initialPhoneCountry);
        if (fields) {
          setFields(emptyAccountFields);
        }
      }
    }, [isOpen]);

    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="account-manager-drawer"
      >
        <Drawer.Header
          label={t("iTd.accountManager.addAccount")}
          onClose={() => setIsOpen(false)}
        />
        <Drawer.Content>
          <Input
            label={t("iTd.accountManager.field.name")}
            placeholder={t("iTd.accountManager.field.name.placeholder")}
            value={fields.name}
            onChange={handleChange}
            onBlur={() => {
              if (!isFieldEmpty("name")) {
                isFormatInvalid("name", NAME_REGEX);
              }
            }}
            required
            name="name"
            status={fieldErrors?.name ? "error" : undefined}
            helpText={getFieldError("name")}
          />
          <Input
            label={t("iTd.accountManager.field.surname")}
            placeholder={t("iTd.accountManager.field.surname.placeholder")}
            value={fields.surname}
            onChange={handleChange}
            onBlur={() => {
              if (!isFieldEmpty("surname")) {
                isFormatInvalid("surname", NAME_REGEX);
              }
            }}
            required
            name="surname"
            status={fieldErrors?.surname ? "error" : undefined}
            helpText={getFieldError("surname")}
          />
          <Input
            label={t("iTd.accountManager.field.email")}
            placeholder={t("iTd.accountManager.field.email.placeholder")}
            value={fields.email}
            onChange={handleChange}
            onBlur={() => {
              if (!isFieldEmpty("email")) {
                isFormatInvalid("email", EMAIL_REGEX);
              }
            }}
            required
            name="email"
            status={fieldErrors?.email ? "error" : undefined}
            helpText={getFieldError("email")}
          />
          {country && (
            <PhoneInput
              label={t("iTd.accountManager.field.phone")}
              placeholder={t("iTd.accountManager.field.phone.placeholder")}
              value={fields.phone}
              name="phone"
              currentLanguage={i18n.language.split("-")[0] as Locales}
              selectedCountry={country}
              onChangeCountry={handleChangeCountry}
              onChange={handleChange}
              status={fieldErrors?.phone ? "error" : undefined}
              helpText={getFieldError("phone")}
              extraLabel={`(${t("iTd.field.optional")})`}
            />
          )}
          <Select
            label={t("iTd.accountManager.field.language")}
            placeholder={t("iTd.accountManager.field.language.placeholder")}
            value={fields.language as OptionSelect}
            options={languageOptions}
            onChange={(value) =>
              handleSelectChange("language", value as OptionSelect)
            }
            onMenuClose={() => isFieldEmpty("language")}
            closeMenuOnSelect
            isRequired
            error={!!fieldErrors?.language}
            helpText={getFieldError("language")}
            menuPlacement="auto"
          />
          <Select
            label={t("iTd.accountManager.field.role")}
            placeholder={t("iTd.accountManager.field.role.placeholder")}
            value={fields.role as OptionSelect}
            options={roleOptions}
            onChange={(value) =>
              handleSelectChange("role", value as OptionSelect)
            }
            onMenuClose={() => isFieldEmpty("role")}
            closeMenuOnSelect
            isRequired
            error={!!fieldErrors?.role}
            helpText={getFieldError("role")}
            menuPlacement="auto"
          />
          <Select
            isMulti
            value={fields.assets as OptionSelect[]}
            isRequired
            helpText={getFieldError("assets")}
            isLoading={!store.projectsFilterOptions?.length}
            options={store.projectsFilterOptions ?? []}
            placeholder={t("iTd.accountManager.field.assets.placeholder")}
            label={t("iTd.accountManager.field.assets")}
            noOptionsMessage={t("iTd.reports.initiative.noOptions")}
            searchPlaceholder={t("iTd.reports.initiative.search")}
            selectAllLabel={t("iTd.reports.initiative.selectAll")}
            deselectAllLabel={t("iTd.reports.initiative.deselectAll")}
            onChange={handleOnUpdateFilters}
            onMenuClose={() => isFieldEmpty("assets")}
            showSelectedItemRemoveIcon={false}
            error={!!fieldErrors?.assets}
            menuPlacement="auto"
          />
        </Drawer.Content>
        <Drawer.Footer>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            {t("iTd.accountManager.cancel")}
          </Button>
          <Button onClick={handleSubmit}>
            {backOfficeUserITD.loading && (
              <Spinner type="dotted-circle" size="small" />
            )}
            {t("iTd.accountManager.add")}
          </Button>
        </Drawer.Footer>
      </Drawer>
    );
  },
);

export default AccountManagerDrawer;
