import { useTranslation } from "@tecma/i18n";
import { useMutation } from "@apollo/client";

import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import type {
  AccountFieldErrors,
  AccountFields,
} from "components/AccountManagerDrawer/AccountManagerDrawer";
import ERRORS from "constants/errors";

const getErrorName = (error: any) => {
  if (error.details?.length) {
    return error.details[0].name;
  }
  return error.name || "";
};

const useCreateBackOfficeUser = (
  fields: AccountFields,
  closeDrawer: () => void,
  setFieldErrors?: React.Dispatch<
    React.SetStateAction<AccountFieldErrors | undefined>
  >,
) => {
  const store = useStore();
  const { t } = useTranslation();
  const [createBackOfficeUserITD, backOfficeUserITD] = useMutation(
    Client.CREATE_BACK_OFFICE_USER_ITD,
  );
  const showErrorSnackbar = (title: string, description: string) => {
    store.setSnackbar({
      type: "error",
      title,
      description,
    });
  };
  const handleErrors = (error: string) => {
    if (
      error === ERRORS.USER.ALREADY_EXISTS.name ||
      error === ERRORS.USER.ALREADY_EXISTS.details[0].name
    ) {
      if (setFieldErrors) {
        setFieldErrors((err) => ({ ...err, email: "emailAlreadyExists" }));
        return;
      }
    }
    if (
      error === ERRORS.USER.NOT_AUTHORIZED.name ||
      error === ERRORS.AUTH.UNAUTHORIZED.name ||
      error === ERRORS.USER.NOT_ALLOWED.name
    ) {
      showErrorSnackbar(
        t(`iTd.accountManager.error.userNotAuthorized.title`),
        t(`iTd.accountManager.error.userNotAuthorized.description`),
      );
      closeDrawer();
      return;
    }
    if (error === ERRORS.USER.DISABLED.name) {
      showErrorSnackbar(
        t(`iTd.accountManager.error.userDisabled.title`),
        t(`iTd.accountManager.error.userDisabled.description`),
      );
      closeDrawer();
      return;
    }
    showErrorSnackbar(
      t(`iTd.accountManager.error.unexpected.title`),
      t(`iTd.accountManager.error.unexpected.description`),
    );
    closeDrawer();
  };
  const createBackOfficeUser = () => {
    const input = {
      firstName: fields.name?.trim(),
      lastName: fields.surname?.trim(),
      email: fields.email?.trim(),
      language: fields.language?.value,
      role: fields.role?.value,
      project_ids: fields.assets?.map((asset) => asset.value),
      ...(fields.phone && { tel: fields.phone.trim() }),
    };
    try {
      createBackOfficeUserITD(
        Client.CREATE_BACK_OFFICE_USER_ITD_DEFAULT_OPTIONS(
          store.demoProject?.id || "",
          input,
        ),
      )
        .then((res) => {
          if (res.errors?.length) {
            handleErrors(getErrorName(res.errors[0].extensions?.exception));
          } else if (res.data?.createBackOfficeUserITD) {
            store.setSnackbar({
              type: "success",
              title: t(`iTd.accountManager.success.userCreated.title`),
              description: t(
                `iTd.accountManager.success.userCreated.description`,
              ),
            });
            closeDrawer();
          }
        })
        .catch((error) => {
          const graphQLError = error.graphQLErrors[0];
          handleErrors(getErrorName(graphQLError.extensions?.exception));
        });
    } catch (error) {
      handleErrors(error as string);
    }
  };
  return { createBackOfficeUser, backOfficeUserITD };
};
export default useCreateBackOfficeUser;
