import React, { useState } from "react";
import { observer } from "mobx-react";
import { useLazyQuery } from "@apollo/client";
import classNames from "classnames";
import { useTranslation } from "@tecma/i18n";
import { Button, Card, Icon, Input, Spinner } from "@tecma/ds";

import Client from "gqlclient/Client";
import { useStore } from "store/storeUtils";
import SimplePage from "components/common/SimplePage/SimplePage";
import { AccountManagerDrawer } from "components/AccountManagerDrawer";
import { AccountManagerTable } from "components/AccountManagerTable";
import useFieldValidator from "customHooks/useFieldValidator";
import { EMAIL_REGEX } from "constants/regex";
import type { AccountFieldErrors } from "components/AccountManagerDrawer/AccountManagerDrawer";
import type { Role } from "types/role";

import "./account-manager.scss";

interface AccountManagerProps {
  className?: string;
  id?: string;
  style?: React.CSSProperties;
}

export type BackOfficeUsers = {
  createdOn: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  notificationLanguage: string;
  project_ids: string[];
  role: Role;
  tel?: string;
};

const AccountManager: React.FC<AccountManagerProps> = observer(() => {
  const { t } = useTranslation();
  const store = useStore();
  const [isAddAccountMenuOpen, setIsAddAccountMenuOpen] = useState(false);
  const [searchEmail, setSearchEmail] = useState("");
  const [fieldErrors, setFieldErrors] = useState<AccountFieldErrors>();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [getBackOfficeUsersITD, backOfficeUsersITD] = useLazyQuery(
    Client.GET_BACK_OFFICE_USERS_ITD,
  );
  const showSearchResults =
    backOfficeUsersITD?.called && !backOfficeUsersITD?.loading;
  const { isMaxLengthExceeded, isFormatInvalid, hasFormErrors, getFieldError } =
    useFieldValidator({ email: searchEmail }, fieldErrors, setFieldErrors);

  const searchContainerClassList = classNames("search-container", {
    error: fieldErrors?.email,
  });

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  const validateEmail = () => {
    if (searchEmail.trim().length === 0) return false;
    if (isMaxLengthExceeded("email", searchEmail.length, 40)) return false;
    if (isFormatInvalid("email", EMAIL_REGEX)) return false;
    return true;
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (fieldErrors?.email) {
      setFieldErrors({ ...fieldErrors, email: undefined });
    }
    setSearchEmail(e.target.value.trim());
  };
  const searchAccount = (e?: React.FormEvent<HTMLElement>) => {
    e?.preventDefault();
    if (
      searchEmail.trim().length === 0 ||
      !validateEmail() ||
      hasFormErrors()
    ) {
      return;
    }
    getBackOfficeUsersITD(
      Client.GET_BACK_OFFICE_USERS_ITD_DEFAULT_OPTIONS(
        {
          email: searchEmail.trim(),
          project_ids: store.actualProjectsIds,
        },
        // page + 1,
        // pageSize,
      ),
    )
      .then((res) => {
        if (res.data?.getBackOfficeUsersITD) {
          setTableData(
            res.data.getBackOfficeUsersITD.map((user: BackOfficeUsers) => ({
              ...user,
              project_ids: user.project_ids.filter(
                (id: string) => !store.allDemoProjectsId?.includes(id),
              ),
            })),
          );
        }
      })
      .catch((err) => {
        setTableData([]);
      });
  };

  // TODO: use only if endpoint support pagination
  // React.useEffect(() => {
  //   if (backOfficeUsersITD.called) {
  //     searchAccount();
  //   }
  // }, [page, pageSize]);

  return (
    <>
      <SimplePage
        title={t("iTd.accountManager.title")}
        description={t("iTd.accountManager.description")}
      >
        <div className="account-manager">
          <Card fluid>
            <Card.Header>
              <div className="header-content">
                <p className="title">{t("iTd.accountManager.searchAccount")}</p>
                <p className="description">
                  {t("iTd.accountManager.searchAccount.description")}
                </p>
              </div>
              <Button
                iconName="plus"
                onClick={() => setIsAddAccountMenuOpen(true)}
                color="transparent"
              >
                {t("iTd.accountManager.addAccount")}
              </Button>
            </Card.Header>
            <Card.Content className="content">
              <form
                onSubmit={searchAccount}
                className={searchContainerClassList}
              >
                <Input
                  label={t("iTd.accountManager.field.email.label")}
                  placeholder={t("iTd.accountManager.field.email.placeholder")}
                  onChange={handleEmailChange}
                  onBlur={validateEmail}
                  value={searchEmail}
                  status={fieldErrors?.email ? "error" : undefined}
                  helpText={getFieldError("email")}
                />
                <Button
                  onClick={searchAccount}
                  iconName={!backOfficeUsersITD.loading && "search"}
                >
                  {backOfficeUsersITD.loading && (
                    <Spinner type="dotted-circle" size="small" />
                  )}
                  {t("iTd.genericTable.search")}
                </Button>
              </form>
              {showSearchResults ? (
                tableData.length > 0 ? (
                  <AccountManagerTable
                    tableData={tableData}
                    loading={backOfficeUsersITD.loading}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                  />
                ) : (
                  <div className="no-search-results">
                    <Icon iconName="exclamation-circle" size="large" />
                    <h3>{t("iTd.accountManager.table.noResults.title")}</h3>
                    <p>{t("iTd.accountManager.table.noResults.description")}</p>
                  </div>
                )
              ) : null}
            </Card.Content>
          </Card>
        </div>
      </SimplePage>
      <AccountManagerDrawer
        isOpen={isAddAccountMenuOpen}
        setIsOpen={setIsAddAccountMenuOpen}
      />
    </>
  );
});

export default React.memo(AccountManager);
