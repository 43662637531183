import React, { useCallback } from "react";
import { useTranslation } from "@tecma/i18n";
import { Tag } from "@tecma/ds";

import { useStore } from "../../store/storeUtils";
import GenericTable from "../common/Table/GenericTable";
import { ROLES } from "../../constants/role";

import "./account-manager-table.scss";

interface AccountManagerTableProps {
  tableData: any[];
  loading?: boolean;
  page?: number;
  pageSize?: number;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}

const DetailPanel = React.memo(
  ({ rowData, getRoleLabel, getAssetLabel, t }: any) => (
    <div className="account-accordion-content">
      <div className="property">
        <p className="label">{t("iTd.accountManager.field.phone")}</p>
        <p className="value">{rowData?.tel || "-"}</p>
      </div>
      <div className="property">
        <p className="label">{t("iTd.accountManager.field.language")}</p>
        <p className="value">
          {rowData?.notificationLanguage
            ? t(`iTd.language.${rowData.notificationLanguage}`)
            : "-"}
        </p>
      </div>
      <div className="property">
        <p className="label">{t("iTd.accountManager.field.role")}</p>
        <p className="value">
          {rowData?.role ? getRoleLabel(rowData.role) : "-"}
        </p>
      </div>
      <div className="property">
        <p className="label">{t("iTd.accountManager.field.assets")}</p>
        <p className="value list-values">
          {rowData?.project_ids?.length
            ? rowData.project_ids.map((assetId: any) => (
                <Tag key={assetId} label={getAssetLabel(assetId)} />
              ))
            : "-"}
        </p>
      </div>
    </div>
  ),
);

const AccountManagerTable: React.FC<AccountManagerTableProps> = ({
  tableData,
  loading,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  const { i18n, t } = useTranslation();
  const store = useStore();

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat(i18n.language, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }).format(date);
  };
  const columns = [
    {
      title: t("iTd.accountManager.table.column.account"),
      field: "account",
      render: (rowData: any) => (
        <div>{`${rowData?.firstName || ""} ${rowData?.lastName || ""}`}</div>
      ),
      sorting: true,
    },
    {
      title: t("iTd.accountManager.table.column.email"),
      field: "email",
      render: (rowData: any) => <div>{rowData?.email}</div>,
      sorting: true,
    },
    {
      title: t("iTd.accountManager.table.column.createdOn"),
      field: "createdOn",
      render: (rowData: any) => (
        <div>
          {rowData?.createdOn ? formatDate(new Date(rowData?.createdOn)) : ""}
        </div>
      ),
      sorting: true,
    },
  ];

  const getRoleLabel = (roleValue: string) => {
    return (
      Object.values(ROLES).find((role) => role.value === roleValue)?.label ||
      roleValue
    );
  };
  const getAssetLabel = (assetId: string) => {
    return (
      store.projects.find((project: any) => project.id === assetId)
        ?.displayName || ""
    );
  };
  const memoizedDetailPanel = useCallback(
    (rowData: any) => (
      <DetailPanel
        rowData={rowData}
        getRoleLabel={getRoleLabel}
        getAssetLabel={getAssetLabel}
        t={t}
      />
    ),
    [],
  );

  return (
    <div className="account-manager-table">
      <GenericTable
        columns={columns}
        toolbar={false}
        data={tableData.map((data: any) => ({ ...data }))}
        detailPanel={memoizedDetailPanel}
        paging
        page={page}
        pageSize={pageSize}
        pageSizeOptions={[5, 10, 25]}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        isLoading={loading}
      />
    </div>
  );
};

export default AccountManagerTable;
