import { observable, action, decorate, computed } from "mobx";
import { cloneDeep, isEqual, omit } from "lodash";
import convertLanguage from "utils/convertLanguage";
import { OptionSelect } from "@tecma/ds/lib/components/Select/Select";
import { ADMIN_ROLE } from "constants/role";
import type { AlertType } from "@tecma/ds/lib/components/Alert/Alert";
import { datadogRum } from "@datadog/browser-rum";

/** Class representing global state of the app.
 * Store is decorated with mobx.
 * It uses the following mobx and mobx-persist decorators:
 * - observable: variable is set as observable (observers will be notified if the variable is updated)
 * - persist: variable will be persisted in a storage
 * - action(.bound): the function is enabled to update the store. Its "this" is bound to the store.
 * - computed: after an observable update, this function automatically updates a complex variable
 * @see {@link https://mobx.js.org/|mobx}
 * @see {@link https://www.npmjs.com/package/mobx-persist|mobx-persist}
 */

export type BrokerObject = {
  name: string;
  id: string;
  brokers: Array<BrokerObject>;
  projects: Array<ProjectObject>;
  actualProjects: Array<ProjectObject>;
  demoProject: ProjectObject;
  iTdConfig: ConfigObject;
  brokerFlags: BrokerFlags;
  url: string;
};

type BrokerFlags = {
  report: boolean;
};

type ConfigObject = {
  languages: Array<string>;
};

export type ProjectObject = {
  storeAddress: string | undefined;
  displayName: string | undefined;
  name: string;
  isCommercialDemo: boolean;
  broker: string;
  id: string;
  enabledTools: Array<EnabledTool>;
};

type UserObject = {
  checkingJwt: boolean;
  userData: LoggedUser;
};

export type EnabledTool = {
  name: string;
  url: string;
  status: string | undefined;
};

type LoggedUser = {
  language: string;
  locale: string | undefined;
  id: string;
  createdOn: Date;
  __typename: string;
  TwoFA: boolean;
  email: string;
  firstName: string;
  lastName: string;
  hasGoogleToken: boolean;
  hasMicrosoftToken: boolean;
  project_ids: Array<string>;
  role: string;
  vendorColor: any;
};

type Snackbar = {
  open?: boolean;
  title: string;
  type: AlertType;
  description?: string;
};

class Store {
  baseUrl: string | undefined;

  redirectionToSsoNeeded: boolean;

  brokerId: string | undefined;

  name: string | undefined;

  checkingJwt: boolean;

  loggedUser: LoggedUser | undefined;

  enabledTools: Array<any>;

  brokers: Array<BrokerObject>;

  projects: Array<ProjectObject>;

  actualProjects: Array<ProjectObject>;

  actualUserAuthorizedProjectsId: Array<string>;

  demoProject: ProjectObject | undefined;

  allDemoProjects: ProjectObject[];

  systemLanguage: string | undefined;

  languages: Array<string> | undefined;

  brokerName: string | undefined;

  iTdConfig: ConfigObject | undefined;

  brokerFlags?: BrokerFlags;

  snackbar?: Snackbar;

  /**
   * initializes store.
   * @constructor
   */
  constructor() {
    this.baseUrl = undefined;
    this.redirectionToSsoNeeded = false;
    this.brokerId = undefined;
    this.name = undefined;
    this.checkingJwt = false;
    this.loggedUser = undefined;
    this.enabledTools = [];
    this.brokers = [];
    this.projects = [];
    this.actualProjects = [];
    this.actualUserAuthorizedProjectsId = [];
    this.demoProject = undefined;
    this.allDemoProjects = [];
    this.systemLanguage = undefined;
    this.languages = undefined;
    this.brokerName = undefined;
    this.iTdConfig = undefined;
    this.brokerFlags = undefined;
    this.snackbar = undefined;
  }

  setAssetsByObject(obj: BrokerObject) {
    if (obj) {
      this.brokerName = obj.name;
      this.brokerId = obj.id;
      this.name = obj.name;
      this.brokers = obj.brokers;
      this.projects = obj.projects;
      this.actualProjects = obj.projects
        .filter((project) => !project.isCommercialDemo)
        ?.sort((a, b) => {
          const nameA = a.displayName?.toUpperCase();
          const nameB = b.displayName?.toUpperCase();

          if (nameA && nameB && nameA < nameB) {
            return -1;
          }
          if (nameA && nameB && nameA > nameB) {
            return 1;
          }

          return 0;
        });
      this.demoProject = obj.projects.find(
        (project) => project.isCommercialDemo && project.broker === obj.id,
      );
      this.allDemoProjects = obj.projects.filter(
        (project) => project.isCommercialDemo,
      );
      this.languages = obj.iTdConfig ? obj.iTdConfig.languages : undefined;
      this.iTdConfig = obj.iTdConfig ? obj.iTdConfig : undefined;
      this.brokerFlags = obj.brokerFlags;
    }
  }

  setBaseUrl(val: string) {
    this.baseUrl = val;
  }

  forceLogout(logout: boolean) {
    this.redirectionToSsoNeeded = logout;
  }

  setCheckingJwt(val: boolean) {
    this.checkingJwt = val;
  }

  setLoggedUserJwtData(obj: UserObject) {
    const { userData, checkingJwt } = obj;
    this.setLoggedUser(userData);
    this.setCheckingJwt(checkingJwt);
  }

  setLoggedUser(userData: LoggedUser) {
    if (
      !this.loggedUser ||
      !isEqual(
        omit(this.loggedUser, ["language", "TwoFA"]),
        omit(userData, ["language", "TwoFA"]),
      )
    ) {
      this.actualUserAuthorizedProjectsId =
        userData.role === ADMIN_ROLE
          ? this.actualProjects.map((project) => project.id)
          : userData.project_ids.filter((id) => {
              return this.actualProjects.find((p) => p.id === id);
            });
      this.loggedUser = userData;
      if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
        datadogRum.setUser({
          id: userData.id,
          name: userData.firstName,
          surname: userData.lastName,
          email: userData.email,
          role: userData.role,
        });
      }
    }
  }

  setSystemLanguage(val: string) {
    this.systemLanguage = val;
  }

  setLoggedUserLang(language: string, locale: string | undefined) {
    if (language && this.loggedUser) {
      const tempLogged = cloneDeep(this.loggedUser);
      tempLogged.language = language;
      this.setLoggedUser(tempLogged);
    }
    if (locale && this.loggedUser) {
      const tempLogged = cloneDeep(this.loggedUser);
      tempLogged.locale = locale;
      this.setLoggedUser(tempLogged);
    }
  }

  setSnackbar(snackbar: Snackbar) {
    this.snackbar = { ...snackbar, open: true };
  }

  closeSnackbar() {
    if (this.snackbar) {
      this.snackbar = { ...this.snackbar, open: false };
      setTimeout(() => {
        this.resetSnackbar();
      }, 200);
    }
  }

  resetSnackbar() {
    this.snackbar = undefined;
  }

  /**
   * resets all persisted data
   */
  logOut() {
    this.checkingJwt = false;
    this.baseUrl = undefined;
    this.languages = undefined;
    this.loggedUser = undefined;
    this.brokerId = undefined;
    this.brokerName = undefined;
    if (process.env.REACT_APP_NAMESPACE === "biz-tecma-prod") {
      datadogRum.clearUser();
    }
  }

  get actualProjectsIds(): Array<string> {
    return this.actualProjects.map((project) => project.id);
  }

  get demoProjectId(): string | undefined {
    return this.demoProject?.id;
  }

  get demoProjectEnabledTools(): Array<EnabledTool> | undefined {
    return this.demoProject?.enabledTools;
  }

  get allDemoProjectsId(): string[] | undefined {
    return this.allDemoProjects.map((project) => project.id);
  }

  get emptyState(): boolean {
    return this.actualUserAuthorizedProjectsId.length === 0;
  }

  get configLanguages() {
    if (Array.isArray(this.languages)) {
      return this.languages.map((lang) => {
        return convertLanguage(lang, undefined);
      });
    }
    return ["it-IT", "en-GB"];
  }

  get projectsFilterOptions(): OptionSelect[] | null {
    if (this.loggedUser && Array.isArray(this.actualProjects)) {
      if (this.loggedUser.role === ADMIN_ROLE) {
        return this.actualProjects.map((project) => ({
          value: project.id,
          label: project.displayName ?? project.name,
        }));
      }
      return (
        this.loggedUser?.project_ids?.reduce<OptionSelect[]>(
          (acc, projectId) => {
            const projectInfo = this.actualProjects.find(
              (p) => p.id === projectId,
            );
            if (projectInfo) {
              return [
                ...acc,
                {
                  value: projectInfo.id,
                  label: projectInfo.displayName ?? projectInfo.name,
                },
              ];
            }
            return acc;
          },
          [],
        ) ?? []
      );
    }
    return null;
  }
}

decorate(Store, {
  baseUrl: observable,
  redirectionToSsoNeeded: observable,
  checkingJwt: observable,
  enabledTools: observable,
  languages: observable,
  brokerName: observable,
  loggedUser: observable,
  iTdConfig: observable,
  brokerFlags: observable,
  snackbar: observable,
  actualUserAuthorizedProjectsId: observable,
  setBaseUrl: action.bound,
  forceLogout: action.bound,
  setAssetsByObject: action.bound,
  setCheckingJwt: action.bound,
  setLoggedUserJwtData: action.bound,
  setSystemLanguage: action.bound,
  setLoggedUserLang: action.bound,
  actualProjectsIds: computed,
  demoProjectId: computed,
  demoProjectEnabledTools: computed,
  allDemoProjectsId: computed,
  configLanguages: computed,
  projectsFilterOptions: computed,
  emptyState: computed,
});

export default Store;
