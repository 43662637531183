const ERRORS = {
  PROJECT: {
    NOT_FOUND: {
      code: "e_1001",
      name: "PROJECT_NOT_FOUND",
      message: "Project not found.",
    },
    NOT_RELATED: {
      code: "e_1003",
      name: "PROJECT_NOT_RELATED",
      message: "Project not related",
      details: [
        {
          code: "e_1003.001",
          name: "PROJECT_NOT_RELATED_BROKER",
          message: "Project not related to broker or parent broker",
        },
      ],
    },
  },
  USER: {
    ALREADY_EXISTS: {
      code: "e_2002",
      name: "USER_ALREADY_EXISTS",
      message: `User with supplied parameters is already present`,
      details: [
        {
          code: "e_2002.001",
          name: "EMAIL_FOR_PROJECT_ALREADY_EXISTS",
          message:
            "User with supplied email is already present for the project",
        },
      ],
    },
    NOT_AUTHORIZED: {
      code: "e_2003",
      name: "USER_NOT_AUTHORIZED",
      message: "User not authorized.",
    },
    DISABLED: {
      code: "e_2004",
      name: "USER_DISABLED",
      message: "User is disabled.",
    },
    NOT_FOUND: {
      code: "e_2005",
      name: "USER_NOT_FOUND",
      message: "User not found.",
    },
    NOT_ALLOWED: {
      code: "e_2006",
      name: "USER_NOT_ALLOWED",
      message: "User not allowed.",
    },
  },
  AUTH: {
    UNAUTHORIZED: {
      code: "e_auth001",
      name: "UNAUTHORIZED",
      message: "Unauthorized.",
    },
  },
  VALIDATION: {
    FIELD_NOT_ALLOWED: {
      code: "e_4001",
      name: "FIELD_NOT_ALLOWED",
      message: "Field not allowed.",
    },
  },
};

export default ERRORS;
