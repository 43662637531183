import { useEffect, useState } from "react";
import { observer } from "mobx-react";

import NavbarGroupLinkItem from "components/common/NavbarItems/components/NavbarGroupLinkItem";
import NavbarLinkItem from "components/common/NavbarItems/components/NavbarLinkItem";
import NavbarUserItem from "components/common/NavbarItems/components/NavbarUserItem";
import getNavbarItems, { NavbarItem } from "utils/getNavbarItems";
import { useStore } from "store/storeUtils";
import type { Role } from "types/role";

import "./navbar-items.scss";

interface NavbarItemsProps {
  mobileMode: boolean;
  collapsedMode?: boolean;
  handleChangeLanguage: (lang?: string) => void;
}

const NavbarItems: React.FC<NavbarItemsProps> = observer(
  ({ mobileMode, collapsedMode, handleChangeLanguage }) => {
    const store = useStore();
    const [navbarItems, setNavbarItems] = useState<NavbarItem[]>([]);

    useEffect(() => {
      const items = getNavbarItems(store);
      const availableNavbarItems = items.filter((item) => {
        if (!item.availableToRoles) {
          return true;
        }
        return item.availableToRoles.includes(store.loggedUser?.role as Role);
      });
      setNavbarItems(availableNavbarItems);
    }, [mobileMode, store, store.loggedUser?.role]);

    return (
      <div className="navbar-routes">
        {navbarItems?.map((navbarItem) => {
          if (navbarItem.type === "routeGroup") {
            return (
              <NavbarGroupLinkItem
                key={navbarItem.titleLabel ?? navbarItem.titleLabel}
                {...navbarItem}
                collapsedMode={collapsedMode}
              />
            );
          }
          if (navbarItem.type === "user" && !mobileMode) {
            return (
              <NavbarUserItem
                key={navbarItem.titleLabel}
                {...navbarItem}
                handleChangeLanguage={handleChangeLanguage}
                collapsedMode={collapsedMode}
              />
            );
          }

          if (navbarItem.type === "link") {
            return (
              <NavbarLinkItem
                key={navbarItem.titleLabel}
                {...navbarItem}
                showTooltip={collapsedMode}
              />
            );
          }
          return null;
        })}
      </div>
    );
  },
);

export default NavbarItems;
