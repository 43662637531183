import {
  isValidNumber,
  PhoneCountry,
} from "@tecma/ds/lib/components/PhoneInput/utils/functions";
import { useTranslation } from "@tecma/i18n";

import type {
  AccountFieldErrors,
  AccountFields,
} from "components/AccountManagerDrawer/AccountManagerDrawer";

const useFieldValidator = (
  fields: AccountFields,
  fieldErrors: AccountFieldErrors | undefined,
  setFieldErrors: (
    value: React.SetStateAction<AccountFieldErrors | undefined>,
  ) => void,
) => {
  const { t } = useTranslation();
  const isFieldEmpty = (field: string) => {
    const currField = fields[field as keyof AccountFields];
    if (
      (typeof currField === "string" && !currField.trim()) ||
      !currField ||
      (Array.isArray(currField) && !currField?.length)
    ) {
      setFieldErrors((err) => ({
        ...err,
        [field]: "isRequired",
      }));
      return true;
    }
    return false;
  };
  const isMaxLengthExceeded = (
    field: string,
    currLength: number,
    maxLength: number,
  ) => {
    if (currLength > maxLength) {
      setFieldErrors((err) => ({ ...err, [field]: "exceedMaxLength" }));
      return true;
    }
    return false;
  };
  const isFormatInvalid = (field: keyof AccountFields, regex: RegExp) => {
    const value = (fields[field] || "") as string;
    if (!regex.test(value)) {
      setFieldErrors((err) => ({ ...err, [field]: "invalidFormat" }));
      return true;
    }
    return false;
  };
  const isValidPhoneNumber = (phoneCountry: string, phone?: string) => {
    const isValid = isValidNumber(
      phoneCountry as PhoneCountry,
      phone || fields.phone || "",
    );
    if (!isValid) {
      setFieldErrors((err) => ({ ...err, phone: "invalidFormat" }));
    }
    return isValid;
  };
  const hasFormErrors = () => {
    if (fieldErrors) {
      return Object.keys(fieldErrors).some(
        (error) => fieldErrors[error as keyof AccountFieldErrors],
      );
    }
    return false;
  };
  const getFieldMaxLength = (field: keyof AccountFields) => {
    if (field === "email") {
      return 40;
    }
    return 50;
  };
  const getFieldError = (field: keyof AccountFields) => {
    if (fieldErrors && fieldErrors[field]) {
      return t(`iTd.field.error.${fieldErrors[field]}`, {
        maxLength: getFieldMaxLength(field),
        field: t(`iTd.accountManager.field.${field}`).toLocaleLowerCase(),
      });
    }
    return null;
  };
  return {
    isFieldEmpty,
    isMaxLengthExceeded,
    isFormatInvalid,
    isValidPhoneNumber,
    hasFormErrors,
    getFieldError,
    getFieldMaxLength,
  };
};
export default useFieldValidator;
